export class Product {
  id: number;
  identifier: string;
  name: string;

  description: string;
  price: number;
  imageLink: string;

  categoryId: number;
  supplyierId: number;
  supplyier: any;

  imageGalleryLinks: string[];
  inStock: boolean;
  points: number;

  isActiv: boolean;

  canBeAddedToCart: boolean;
}

